const easeOutCubic = ( t ) => ( t - 1 ) ** 3 + 1;

const animateHeight = ({
  container, content, duration, maxHeight = Infinity,
}) => ({
  open,
  minHeight = 0,
  callback = null,
}) => {
  let openHeight = 0;
  if ( content ) {
    const contentStyle = getComputedStyle( content );
    openHeight = Math.min(
      content.offsetHeight
        + parseInt( contentStyle.marginTop, 10 )
        + parseInt( contentStyle.marginBottom, 10 ),
      maxHeight,
    );
  }
  const elasticHeight = openHeight - minHeight;

  let start = null;
  const animate = ( timestamp ) => {
    if ( !start ) {
      start = timestamp;
    }
    const progress = timestamp - start;

    // step
    if ( progress < duration ) {
      container.style.height = !open
        ? `${minHeight + easeOutCubic( progress / duration ) * elasticHeight}px` // open
        : `${minHeight + ( 1 - easeOutCubic( progress / duration )) * elasticHeight}px`; // close

      requestAnimationFrame( animate );
    } else {
      // finish
      if ( !open ) {
        container.style.removeProperty( 'height' );
      } else {
        container.style.height = minHeight;
      }

      if ( callback ) {
        callback();
      }
    }
  };

  requestAnimationFrame( animate );
};

export default animateHeight;
